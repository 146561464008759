import React, { useState } from "react";
import ContactDetails from './ContactDetails'
import {
    MDBContainer,
    MDBCard,
    MDBRow,
    MDBCol,
    MDBCardBody,

} from "mdb-react-ui-kit"

import { faHandsHelping, faCashRegister, faPhone, faEnvelope, faMehRollingEyes, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactUs = ({ props, user }) => {

    return (
        <React.Fragment>
            <MDBContainer>
                <MDBRow className='row-cols-1 row-cols-md-2 g-4 my-2'>
                    <MDBCol>
                        <MDBCard shadow='1' border='secondary' background='white'>
                            <MDBCardBody style={{ color: props.textIconColour, }}>
                                <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faHandsHelping} size="6x" />
                                <ContactDetails title={'RMA Support'} subtitle={'Contact RMA'}
                                    message={'Make a general RMA support enquiry.'} contact={''} phone={props.mainPhone}
                                    email={props.mainEmail} props={props} user={user} />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol>
                        <MDBCard shadow='1' border='secondary' background='white'>
                            <MDBCardBody style={{ color: props.textIconColour, }}>
                                <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faCashRegister} size="6x" />
                                <ContactDetails title={'Sales Enquiry'} subtitle={'Contact Sales'}
                                    message={'Make an enquiry with sales'} phone={props.mainSalesPhone} email={props.mainSalesEmail}
                                    props={props} user={user} />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='row-cols-1 row-cols-md-2 g-4 my-2'>
                    <MDBCol>
                        <MDBCard shadow='1' border='secondary' background='white'>
                            <MDBCardBody style={{ color: props.textIconColour, }}>
                                <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faCommentDots} size="6x" />
                                <ContactDetails title={'Suggestions'} subtitle={'Make a suggestion'}
                                    message={'Send a suggestion directly to the development team'} contact={''} phone={''}
                                    email={props.mainDevEmail} props={props} user={user} />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </React.Fragment>
    );
}

export default ContactUs;
