import React, { useState } from 'react';
import RmaList from './rma/RmaList';
import CreateRma from './rma/CreateRma';
import Home from './Home';
import Manage from '../user/Manage';
import ManageUsers from '../user/ManageUsers';
import ContactUs from '../contact/ContactUs'
import { ReactComponent as CnbLogo } from '../../CnbLogo.svg';
import { ReactComponent as RefurbLogo } from '../../Refurbio.svg';
import { ReactComponent as SysLiqLogo } from '../../SysLiqLogo.svg';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    MDBNavbar,
    MDBContainer,
    //MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarToggler,
    MDBCollapse,
    MDBNavbarBrand,

} from 'mdb-react-ui-kit';

export default function NavigationPage({ props, user, updateShowLogin, product }) {
    const [openNav, setOpenNav] = useState(false);
    const [rmas, setRmas] = useState(null);
    const [rma, setRma] = useState(null);
    const [active, setActive] = useState('Home');
    const [rmaComp, setRmaComp] = useState(null);
    const [navName, setNavName] = useState(user.FullName ? user.FullName : user.UserName);

    const getRmas = (e) => {
        const requestOptions = {
            method: "post",
            headers: props.header,
            redirect: "follow",
            changeOrigin: true,
            body: JSON.stringify(user)
        };

        console.log(`[RmaList][getRmas][${user.UserName}]> getRmas`);
        fetch(`${props.baseUrl}RmaListDetailsUser/`, requestOptions)
            .then((response) => response.json())
            .then((results) => {
                console.log(`[RmaList][getRmas][${user.UserName}]> RMA Response: ${JSON.stringify(results)}`);
                setRmas(results.rmas);
                setActive('rmas');
            }).catch((error) => {
                console.error(`[RmaList][getRmas][${user.UserName}]> There was an issue getting the RMAs: ${error}`);
            })
    }

    const getRmaComp = (e) => {
        const requestOptions = {
            method: "post",
            headers: props.header,
            redirect: "follow",
            changeOrigin: true,
            body: JSON.stringify({ User: user, UserName: user.UserName, Token: user.Token }),
        };

        fetch(`${props.baseUrl}User/Company`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(`[Manage][getRmaComp]> Result: ${result}`);
                /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                result = JSON.parse(result);
                if (result && result.status && result.status === 200) {
                    console.log(`[Manage][getRmaComp][SUCCESS]> ${result}`);
                    setRmaComp(result.rmacomp);
                    manageAccount();
                } else {
                    console.log(`[Manage][getRmaComp][FAILED]> ${result}`);
                    if (result && result.message) {
                        alert(result.message);
                    } else {
                        alert('There appears to have been an issue getting the company details...');
                    }
                }
            })
            .catch((error) => console.error('[Manage][getRmaComp][ERROR]> There was an issue logging in: ' + error));

    }
    const createRma = (rma) => {
        setActive('Create');
        if (rma) setRma(rma);
        setOpenNav(false);
    }

    const manageAccount = () => {
        setActive('Manage');
        setOpenNav(false);
    }
    const manageUsers = () => {
        setActive('ManageUsers');
        setOpenNav(false);
    }

    const clickHome = () => {
        setActive('Home');
        setOpenNav(false);
    }

    const contactUs = () => {
        setActive('ContactUs');
        setOpenNav(false);
    }

    const getGreeting = () => {
        switch (user.Language) {
            case 'FRE': return 'Bonjour';
            case 'ENG':
            default: return 'Hello';
        }
    }

    return (
        <React.Fragment>
            <MDBNavbar sticky light bgColor='light'>
                <MDBContainer fluid>
                    <MDBNavbarBrand onClick={() => setActive('Home')} href='#'>
                        <img
                            src={ props.navbarImage}
                            height='30'
                            alt=''
                            loading='lazy'
                        />
                        {`${props.companyName} | ${getGreeting()}, ${navName}`}
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        type='button'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => setOpenNav(!openNav)}
                    >
                        <FontAwesomeIcon icon={faBars} />
                    </MDBNavbarToggler>
                    <MDBCollapse navbar open={openNav}>
                        <MDBNavbarNav className='me-auto mb-2 mb-lg-0'>
                            <MDBNavbarItem>
                                <MDBNavbarLink active aria-current='page' onClick={ clickHome} href='#'>Home</MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink onClick={getRmas} href='#'>RMA List</MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink onClick={createRma} href='#'>Create RMA</MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink onClick={getRmaComp} href='#'>Manage Account</MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink onClick={manageUsers} href='#'>Manage Users</MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink onClick={contactUs} href='#'>Contact Us</MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink onClick={() => updateShowLogin(true)} href='#'>Logout</MDBNavbarLink>
                            </MDBNavbarItem>
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
            {rmas && active === 'rmas' ? <RmaList props={props} user={user} rmas={rmas} updateRma={createRma} /> : null}
            {active === 'Create' ? <CreateRma props={props} addToRma={rma} user={user} product={product} /> : null}
            {active === 'Home' ? <Home props={props} setActive={setActive} getRmas={getRmas} manageUsers={manageUsers} getRmaComp={getRmaComp} updateShowLogin={updateShowLogin} contactUs={contactUs} user={user}/> : null}
            {active === 'Manage' ? <Manage props={props} user={user} rmaComp={rmaComp} setNavName={setNavName} /> : null}
            {active === 'ManageUsers' ? <ManageUsers props={props} currentUser={user} setNavName={setNavName} /> : null}
            {active === 'ContactUs' ? <ContactUs props={props} user={user} /> : null}
        </React.Fragment>
    );
}