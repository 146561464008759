import React, { useState, useEffect } from "react";
import ManageUserDetails from './ManageUserDetails';
import ForgotPassword from "./ForgotPassword";
import AddUser from "./AddUser";
import { faUserFriends, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    //MDBCardBody,
    //MDBCardImage,
    MDBRow,
    MDBCol,
    //MDBIcon,
    MDBInput,
    MDBAccordion,
    MDBAccordionItem
} from 'mdb-react-ui-kit';
const ManageUsers = ({ props, currentUser, setNavName}) => {
    const [userSearch, setUserSearch] = useState(null);
    const [active, setActive] = useState(0);
    const [users, setUsers] = useState([]);
    useEffect(() => { }, [users, userSearch]);
    const [isUserValid, setIsUserValid] = useState(false);

    const getUsers = () => {
        const requestOptions = {
            method: "post",
            headers: props.header,
            redirect: "follow",
            changeOrigin: true,
            body: JSON.stringify(
                {
                    UserName: currentUser.UserName,
                    Token: currentUser.Token,
                    User: currentUser,
                }
            ),
        };

        fetch(`${props.baseUrl}Company/Users`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(`[ManageUsers][getUsers]> Result: ${result}`);
                /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                result = JSON.parse(result);
                if (result && result.status && result.status === 200) {
                    console.log(`[ManageUsers][getUsers][SUCCESS]> ${result}`);
                    setUsers(result.users);
                } else {
                    console.log(`[ManageUsers][getUsers][FAILED]> ${result}`);
                    if (result && result.message) {
                        alert(result.message);
                    } else {
                        alert('There appears to have been an issue with getting the users details...');
                    }
                }
            })
            .catch((error) => console.error('[ManageUsers][getUsers][ERROR]> There was an getting company users details: ' + error));
    }

    if (users.length < 1) {
        getUsers();
    }

    const search = (e) => {
        setUserSearch(e.target.value);
    }

    return (
        <React.Fragment>
            <MDBContainer className="my-5">
                <MDBInput wrapperClass='mb-4' label='Search Users' id='formControlSearch' type='text' size="lg"
                    onChange={search}
                />

                <MDBCard>
                    <MDBRow className='g-0'>
                        <MDBCol md='12'>
                            <MDBAccordion active={active} onChange={(itemId) => setActive(itemId)}>
                                <MDBAccordionItem key={users.length + 1} collapseId={users.length + 1} headerTitle={
                                    <>
                                        <FontAwesomeIcon style={{ color: props.passIconColour }} icon={faPlusCircle} size="2x" className="me-3 mx-3" />  &nbsp; {`Add a New User `} &nbsp;<FontAwesomeIcon style={{ color: props.textIconColour }} icon={faUserFriends} size="2x" className="me-3 mx-3" /></>}>
                                    <AddUser props={props} user={currentUser} getUsers={getUsers} />
                                </MDBAccordionItem>
                                {users && users.length > 0 ?
                                    users.map((user, i) =>
                                        !userSearch || user.FullName.includes(userSearch) || user.UserName.includes(userSearch) ?
                                            <MDBAccordionItem key={i + 1} collapseId={i + 1} headerTitle={`${user.FullName} (${user.UserName}) - ${user.UserAccess}`}>

                                                <ForgotPassword props={props} loginEmail={user.UserName} isManage={ true} />
                                                <ManageUserDetails props={props} user={currentUser} userDetails={user} setIsUserValid={setIsUserValid} setNavName={setNavName} />
                                            </MDBAccordionItem> : null

                                    ) : null}
                            </MDBAccordion>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBContainer>
        </React.Fragment>
    );
}


export default ManageUsers;