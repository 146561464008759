import React, { useState, useEffect } from "react";
import RmaLine from "./RmaLine";
import styles from "./RmaList.module.css";
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    //MDBCardBody,
    //MDBCardImage,
    MDBRow,
    MDBCol,
    //MDBIcon,
    MDBInput,
    MDBAccordion,
    MDBAccordionItem
} from 'mdb-react-ui-kit';

let lineSearchFields = ['SerialNumber','TrackingNumberIn', 'DESCRIPTION', 'INVOICENO','REASON'];

const RmaList = ({ props, user, rmas, updateRma}) => {
    const [rmaSearch, setRmaSearch] = useState(null);
    const [active, setActive] = useState(1);
    useEffect(() => { }, [rmas, rmaSearch]);
    console.log('RmaList RMAs:', (rmas && rmas.length > 0), rmas);

    let btnClass = `mb-4 px-5 ${(props.doOutlineButton ? `btn-outline-${props.buttonColour}` :'')}`;
    const search = (e) => {
        setRmaSearch(e.target.value);
    }

    const checkRmaLineSearch = (rmaSearch, rma) => {
        for (var line of rma.rma) {
            for (var field of lineSearchFields) {
                if (line && line[field] && line[field].includes(rmaSearch)) {
                    return true;
                }
            } 
        }
        return false;
    }

    return (
        <React.Fragment>
            <MDBContainer style={styles} className="my-5">
                <MDBInput wrapperClass='mb-4' label='Search RMA Details' id='formControlSearch' type='text' size="lg"
                    onChange={search}
                    />
                <MDBCard>
                    <MDBRow className='g-0'>
                        <MDBCol md='12'>
                            <MDBAccordion active={active} onChange={(itemId) => setActive(itemId)}>
                                {rmas && rmas.length > 0 ?
                                    rmas.map((rma, i) => 
                                        !rmaSearch || rma.rmaNumber.includes(rmaSearch) || rma.Status.includes(rmaSearch) || checkRmaLineSearch(rmaSearch, rma)?
                                            <MDBAccordionItem key={i + 1} collapseId={i + 1} headerTitle={`${rma.rmaNumber} (${rma.Status.trim()}) - Count: ${rma.rma.length}`}>
                                                {['Requested', 'Open'].includes(rma.Status.trim()) ? <MDBBtn key={`addBtn${i + 1}`} id={`addBtn${i + 1}`} className={btnClass} color={props.buttonColour}  size='lg' onClick={() => updateRma(rma.rma[0])}> {'Add to RMA'}</MDBBtn>: ''}
                                                <RmaLine props={props} rma={rma.rma} user={user} />
                                            </MDBAccordionItem>:null      
                                    ) : null}
                            </MDBAccordion>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBContainer>
        </React.Fragment>
    );
}


export default RmaList;