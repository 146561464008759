import React, { useState, useEffect } from "react";
import Dropdown from "../categories/CategoryDropdown";

//import styles from "./SignUp.module.css";
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    //MDBCardImage,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInput
} from 'mdb-react-ui-kit';

import { faUserFriends,  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const accessOptions = [{ ID: "owner", Value: 'owner' }, { ID: "full", Value: 'full' },];
const languageOptions = [{ ID: "ENG", Value: 'English' }, { ID: "FRE", Value: 'French' },];
const NAME_TOO_SHORT_ERROR = 'Name should be 2 or more characters';
const NAME_TOO_LONG_ERROR = 'Name should be 50 characters or less';
const AddUser = ({ props, user, getUsers }) => {

    let btnClass = `mb-4 px-5 ${(props.doOutlineButton ? `btn-outline-${props.buttonColour}` : '')}`;
    const [fullName, setFullName] = useState( null);    
    const [fullNameError, setFullNameError] = useState(null);
    const [errorEmail, setErrorEmail] = useState(null);
    const [preferredLanguage, setPreferredLanguage] = useState({ ID: 'ENG', Value: 'English' });
    const [emailAddress, setEmailAddress] = useState(null);
    const [userAccess, setUserAccess] = useState('full');
    const [isUserValid, setIsUserValid] = useState(false);
    useEffect(() => {
        checkUserValid();
    }, [fullNameError, fullName, preferredLanguage, userAccess, emailAddress]);

    /**
     * Used for onChange in text box which feeds argument, checks validity and sets state
     * @param {any} e
     */
    function updateEmail(e) {
        setEmailAddress(e.target.value);
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        console.log(`updateEmail: ${e.target.value}`, 'Pass RegEx:', (emailRegex.test(e.target.value)), 'Pass Length: ', !(e.target.value.length < 5));
        if (!emailRegex.test(e.target.value)) {
            checkUserValid();
            setErrorEmail('Please enter a valid email address');
        } else {
            setErrorEmail(null);
            checkUserValid();
            return true;
        }
    }
    const updateFullName = (e) => {
        console.log(`updateFullName: ${e.target.value}`);
        setFullName(e.target.value);
        if (e.target.value.length > 50) {
            setFullNameError(NAME_TOO_LONG_ERROR);
            checkUserValid();
            return;
        } else if (e.target.value.length < 2) {
            setFullNameError(NAME_TOO_SHORT_ERROR);
            return;
        } else {
            setFullNameError(null);
        }
    }
    const updatePreferredLanguage = (id, name) => {
        console.log(`updatePreferredLanguage: ${id} ${name}`);
        setPreferredLanguage({ ID: id, Value: name });
    };

    const updateUserAccess = (id, name) => {
        console.log(`updateUserAccess: ${id} ${name}`);
        setUserAccess({ ID: id, Value: name });
    }

    const checkUserValid = () => {
        if (fullName && !fullNameError && emailAddress && !errorEmail && userAccess && preferredLanguage) {
            setIsUserValid(true);
        } else {
            setIsUserValid(false);
        }
    }

    const addUser = () => {
        if (!isUserValid) return;
        let body = {
            UserName: user.UserName,
            Token: user.Token,
            Page: 'addUser',
            User: user,
            NewUserDetails: {
                UserName: emailAddress,
                FullName: fullName,
                UserAccess: userAccess.ID,
                PreferredLanguage: preferredLanguage.ID,
                CompanyID: user.CompanyID,
                RegisteredSite: window.location.href.split('#')[0].split('?')[0]
            },
            Site: props.brand,
            SalesEmail: props.mainSalesEmail,
            MainEmail: props.mainEmail,
            RegisteredURL: window.location.href.split('#')[0].split('?')[0],
        }

        const requestOptions = {
            method: "post",
            headers: props.header,
            redirect: "follow",
            changeOrigin: true,
            body: JSON.stringify(body),
        };

        fetch(`${props.baseUrl}User/addNewUser`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(`[AddUser][addUser]> Result: ${result}`);
                /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                result = JSON.parse(result);
                if (result && result.status && result.status === 200) {
                    console.log(`[AddUser][addUser][SUCCESS]> ${result}`);
                    alert(result.message);
                } else {
                    console.log(`[AddUser][addUser][FAILED]> ${result}`);
                    if (result && result.message) {
                        alert(result.message);
                    } else {
                        alert('There appears to have been an issue with adding the new user...');
                    }
                }
            })
            .catch((error) => console.error('[SignUp][register][ERROR]> There was an issue logging in: ' + error));
        
    }

    return (
        <React.Fragment>            
            <MDBContainer>
                <MDBCard shadow='1' border='secondary' background='white'>
                    <MDBCardBody style={{ color: props.textIconColour, }}>
                        <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faUserFriends} size="6x" className="mb-4" />
                        <MDBCardTitle style={{ color: props.textIconColour, }}>Add Additional User</MDBCardTitle>
                        <MDBCardText style={{ color: props.textIconColour, }}>
                            Add a new user to help manage RMAs
                        </MDBCardText>

                        <MDBInput
                            maxLength={60} wrapperClass='mb-4'
                            label='Email address' id='formControlEmail'
                            type='email' size="lg" onChange={updateEmail}
                            style={errorEmail ? props.errorStyle : null}
                        >
                            <div id='emailErrorLabel' className='form-helper'>
                                {errorEmail}
                            </div>
                        </MDBInput>
                        <MDBInput wrapperClass='mb-4' maxLength={50}
                            onChange={updateFullName} label='Full Name *' id='formControlName'
                            type='text' size="lg" style={fullNameError ? props.errorStyle : null}>
                            <div id='formNameErrorLabel' className='form-helper'>
                                {fullNameError}
                            </div>
                        </MDBInput>
                        <Dropdown options={accessOptions} updateChoice={updateUserAccess} chooseWhat={'User Access'} defaultValue={'full'} />
                        <Dropdown options={languageOptions} updateChoice={updatePreferredLanguage} chooseWhat={'Preferred Language'} value={user && user.Language ? user.Language : 'English'} />

                        <MDBBtn className={btnClass} color={props.buttonColour} size='lg' onClick={addUser} disabled={ !isUserValid }>{'Add User'}</MDBBtn>
                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>
        </React.Fragment>
        );

}
export default AddUser;