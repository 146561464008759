import React, { useState} from "react";
import { getPhoneHref, copyToClipboard} from "../../service";
import ContactNow from './ContactNow'

import { faPhone, faEnvelope, faCopy, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactDetails = ({ message, title, subtitle, phone, email, props, user }) => {
    const [succesCopyPhone, setSuccessCopyPhone] = useState(false);
    const [succesCopyEmail, setSuccessCopyEmail] = useState(false);

    return (
        <React.Fragment>
            <h1 className={"my-3"}>{title}</h1>
            <hr />
            <div className='d-flex flex-row mt-2 '>
                <a href={`mailto: ${email}`}>
                    <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faEnvelope} size="2x" className="me-3 mx-3" />
                </a>
                {succesCopyEmail ?
                    <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faCheckCircle} size="2x" className="mx-3" onClick={() => copyToClipboard(email, setSuccessCopyEmail, setSuccessCopyPhone)} />
                    : <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faCopy} size="2x" className="mx-3" onClick={() => copyToClipboard(email, setSuccessCopyEmail, setSuccessCopyPhone)} />
                }
                <span style={{ color: props.textIconColour, }} className="h4 fw-bold mb-0">{email}</span>
            </div>
            {phone ? <>
                <hr /> <div className='d-flex flex-row mt-2 '>
                    <a href={`tel: ${getPhoneHref(phone)}`}>
                        <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faPhone} size="2x" className="me-3 mx-3" />
                    </a>
                    {succesCopyPhone ?
                        <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faCheckCircle} size="2x" className="mx-3" onClick={() => copyToClipboard(phone, setSuccessCopyPhone, setSuccessCopyEmail)} />
                        : <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faCopy} size="2x" className="mx-3" onClick={() => copyToClipboard(phone, setSuccessCopyPhone, setSuccessCopyEmail)} />
                    }
                    <span style={{ color: props.textIconColour, }} className="h4 fw-bold mb-0">{phone}</span>
                </div> </> : null}
            <hr />
            <ContactNow message={message} title={subtitle} email={email} user={user} props={props} />
        </React.Fragment>
    );
}

export default ContactDetails;
