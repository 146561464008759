import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getIcon, getTrackingURL } from "../../../service";
import { faCheckCircle, faEdit } from '@fortawesome/free-solid-svg-icons';

const RmaLine = ({ props, rma, user }) => {
    const editTracking = (rma, e) => {
        showHideTracking(rma, false);
    }

    const backToReturnTracking = (rma, event) => {
        if (rma.TrackingNumberIn) {
            showHideTracking(rma, true);
        }
    }

    const showHideTracking = (rma, doShow) => {
        let editDiv = document.getElementById(`${rma.rmaNumber}_${rma.AutoNo}_ReturnTracking_edit_div`);
        console.log(`${`${rma.rmaNumber}_${rma.AutoNo}_ReturnTracking_edit_div`} => ShowHide: ${doShow}`, editDiv);
        if (editDiv) {
            editDiv.setAttribute('aria-hidden', doShow);
            if (doShow) editDiv.setAttribute('hidden', doShow);
            else editDiv.removeAttribute('hidden');
        }

        let returnTrackingDiv = document.getElementById(`${rma.rmaNumber}_${rma.AutoNo}_ReturnTracking_div`);
        console.log(`${`${rma.rmaNumber}_${rma.AutoNo}_ReturnTracking_div`} => ShowHide: ${doShow}`, returnTrackingDiv);
        if (returnTrackingDiv) {
            returnTrackingDiv.setAttribute('aria-hidden', !doShow);
            if (doShow) returnTrackingDiv.removeAttribute('hidden');
            else returnTrackingDiv.setAttribute('hidden', !doShow);
        }
    }

    const addTracking = (rma, e) => {
        if (e.key === 'Enter') {
            console.log(`[RmaLine][addTracking]> Adding Tracking for ${rma.rmaNumber} ${rma.AutoNo}: ${e.target.value}`);
            rma.TrackingNumberIn = e.target.value;
            const requestOptions = {
                method: "post",
                headers: props.header,
                redirect: "follow",
                changeOrigin: true,
                body: JSON.stringify({
                    RMA: rma,
                    User: user,
                    UserName: user.UserName,
                    Token: user.Token,
                    UserAccess: user.UserAccess
                }),
            };

            fetch(`${props.baseUrl}RMA/TrackingIn`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    console.log(`[RmaLine][addTracking]> Result: ${result}`);
                    /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                    result = JSON.parse(result);
                    if (result && result.status && result.status === 200) {
                        console.log(`[RmaLine][addTracking][SUCCESS]> ${result}`);
                        alert(result.message);
                        let icon = document.getElementById(`${rma.rmaNumber}_${rma.AutoNo}_ReturnTracking_icon_div`);
                        console.log(icon);
                        icon.setAttribute('aria-hidden', 'false');
                        icon.removeAttribute('hidden');
                    } else {
                        console.log(`[RmaLine][addTracking][FAILED]> ${result}`);
                        if (result && result.message) {
                            alert(result.message);
                        } else {
                            alert('There appears to have been an issue adding the tracking...');
                        }
                    }
                })
                .catch((error) => console.error('[RmaLine][addTracking][ERROR]> There was an issue logging in: ' + error));

        }
    }
    return (
        <React.Fragment>
            {rma ? rma.map((result, i) => (
                <div key={i}>
                    <div className='d-flex flex-row mt-2'>
                        <FontAwesomeIcon style={{ color: props && props.textIconColour ? props.textIconColour : 'dark-gray', }} icon={getIcon(result.ProdType)} className="me-3" size="4x" />
                        <span style={{ color: props && props.textIconColour ? props.textIconColour : 'dark-gray', }} className="h2 fw-bold mb-0">{`${result.rmaNumber} Line(${result.Status}): ${i + 1}`}</span>
                    </div>
                    <p><b>{'Line ID:'}</b> {result.AutoNo}</p>
                    {result.INVOICENO ? <p><b>{'Invoice Number:'}</b>{result.INVOICENO}</p> : null}
                    {result.SERIALNO ? <p><b>{'Serial Number:'}</b>{result.SERIALNO}</p> : null}
                    {result.ReqType ? <p><b>{'Request:'}</b>{result.ReqType}</p> : null}
                    {result.REASON ? <p><b>{'Reason:'}</b>{result.REASON}</p> : null}
                    <p><b>{'Product:'}</b> {result.DESCRIPTION}</p>
                    <p><b>{'Status:'}</b> {result.Status}</p>
                    {result.StatusNotes ? <p><b>{'Status Notes:'}</b>{result.StatusNotes}</p> : null}
                    {result.Waybill ? <p><b>{'Waybill: '}</b><a target="_blank" href={getTrackingURL(result.Waybill)}>{result.Waybill}</a></p> : null}
                    <div id={`${result.rmaNumber}_${result.AutoNo}_ReturnTracking_div`} hidden={result.TrackingNumberIn?false:true}>
                        <p><b>{'Return Tracking Number:'}</b>
                            <a target="_blank" href={getTrackingURL(result.TrackingNumberIn)}>{result.TrackingNumberIn}</a>
                            <FontAwesomeIcon id={`${result.rmaNumber}_${result.AutoNo}_ReturnTracking_edit_icon`}
                            style={{ color: props.textIconColour, }} size="1x" icon={faEdit} className="mx-3"
                            onClick={(event) => editTracking(result, event)} /></p>
                            
                        </div> 
                    <div id={`${result.rmaNumber}_${result.AutoNo}_ReturnTracking_edit_div`} hidden={result.TrackingNumberIn ? true : false}>
                        <b> <label htmlFor={`${result.rmaNumber}_${result.AutoNo}_ReturnTracking`} onClick={(event) => backToReturnTracking(result, event)}>
                            {result.TrackingNumberIn ?
                            <u>{'Add Return Tracking Number:'}</u> : 'Add Return Tracking Number:'} </label>
                          
                            </b><input maxLength='50' name={`${result.rmaNumber}_${result.AutoNo}_ReturnTracking`}
                                id={`${result.rmaNumber}_${result.AutoNo}_ReturnTracking_text`}
                                onKeyDown={(event) => addTracking(result, event)} />
                        <FontAwesomeIcon id={`${result.rmaNumber}_${result.AutoNo}_ReturnTracking_icon_div`}
                            style={{ color: props.passIconColour, }} size="1x" icon={faCheckCircle} className="mx-3" hidden={ true} />

                        </div>

                    {result.TrackingNumberOut ? <p><b>{'Tracking Number: '}</b><a target="_blank" href={getTrackingURL(result.TrackingNumberOut)}>{result.TrackingNumberOut}</a></p> : null}
                    {result.TrackingStatusOut ? <p><b>{'Tracking: '}</b>{result.TrackingStatusOut}</p> : null}
                    {i + 1 !== rma.length ? <hr />:null}
                </div>
            )
            )
                : null}
        </React.Fragment>
    );
}
export default RmaLine;