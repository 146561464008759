import React, { useState } from "react";
import {
    MDBBtn,
    MDBInput
} from 'mdb-react-ui-kit';
const DEFAULT_MESSAGE = 'Please enter your Username below and click the Request button';
const DEFAULT_MANAGE_MESSAGE = 'Click the "Request Password Reset" button and an email with instructions for a new password will be sent to the users email';
const ForgotPassword = ({ props, loginEmail, isManage }) => {
    const [email, setEmail] = useState(loginEmail);
    const [errorEmail, setErrorEmail] = useState(true);
    const [message, setMessage] = useState(DEFAULT_MESSAGE);
    const [isManageMessage, setIsManageMessage] = useState(DEFAULT_MANAGE_MESSAGE);
    const [isLoad, setIsLoad] = useState(true);
    let btnClass = `mb-4 px-5 ${(props.doOutlineButton ? `btn-outline-${props.buttonColour}` : '')}`;
    console.log(window.location.href);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const checkEmail = (email) => {
        if (!emailRegex.test(email)) {
            setErrorEmail('Please enter a valid email address');
        } else {
            setErrorEmail(null);
            return true;
        }
    }

    /**
     * Used for onChange in text box which feeds argument, checks validity and sets state
     * @param {any} e
     */
    function updateEmail(e) {
        setEmail(e.target.value);
        console.log(`updateEmail: ${e.target.value}`, 'Pass RegEx:', (emailRegex.test(e.target.value)), 'Pass Length: ', !(e.target.value.length < 5));
        checkEmail(e.target.value);
        if (!errorEmail && e.key === 'Enter') {
            requestResetEmail();
        }
    }


    const requestResetEmail = () => {
        if (email && !errorEmail) {
            const requestOptions = {
                method: "post",
                headers: props.header,
                redirect: "follow",
                changeOrigin: true,
                body: JSON.stringify({
                    Site: props.brand,
                    ReqURL: window.location.href,
                    UserName: email
                }),
            };

            fetch(`${props.baseUrl}User/Password/Reset`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                    result = JSON.parse(result);
                    if (result && result.status && result.status === 200) {
                        console.log(`[ForgotPassword][requestResetEmail][SUCCESS]> ${result.message}`);
                        setMessage(result.message);
                        setIsManageMessage(result.message);
                    } else {
                        console.log(`[ForgotPassword][requestResetEmail][FAILED]> ${result}`);
                        if (result && result.message) {
                            setMessage(result.message)
                            setErrorEmail('Check your email!');
                        } else {
                            setIsManageMessage('There was an issue making the request...');
                        }
                    }
                })
                .catch((error) => {
                    console.error('[ForgotPassword][requestResetEmail][ERROR]> There was an issue checking the serial number: ' + error);
                    setMessage('There was an issue making the request...');
                });
        }
    }

    if (email && isLoad) {
        setIsLoad(false);
        checkEmail(email);
    }

    return (
        <React.Fragment>
            <h5 className="fw-normal my-4 pb-3" style={{ color: props.textIconColour, letterSpacing: '1px' }}>Request Password Reset</h5>
            {!isManage ? <h6 className="fw-normal my-4 pb-3" style={{ color: props.textIconColour, letterSpacing: '1px' }}>{message}</h6> : 
                <h6 className="fw-normal my-4 pb-3" style={{ color: props.textIconColour, letterSpacing: '1px' }}>{isManageMessage}</h6>}
            <MDBInput wrapperClass='mb-4' label='Username' id='formControlEmail' defaultValue={ email ? email : ''}
                type='email' size="lg" onChange={updateEmail} style={errorEmail ? props.errorStyle : null} >
                <div id='emailErrorLabel' className='form-helper'>
                    {errorEmail}
                </div>
            </MDBInput>
            <MDBBtn className={btnClass} color={props.buttonColour} size='lg' disabled={errorEmail!=null} onClick={requestResetEmail}>{'Request Password Reset Email'}</MDBBtn>
        </React.Fragment>
    )
};

export default ForgotPassword;
