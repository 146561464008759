import { faPhone, faDesktop , faLaptop, faServer, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
export const NOT_ADP = "NOT ADP";
export const getImageSrc = ({ bw, theme, idx }) => {
    const cat = theme || "sports";
    const isbw = bw ? "g" : "c";
    return `https://picsum.photos/seed/picsum/600/600`
};

export const themes = ["RMA", "CnbShieldGuard", "CNB", "Refurbio", "SystemLiquidation"];

const trackingMap = [{ TrackStart: "731", URL: "http://www.canadapost.ca/cpotools/apps/track/personal/findByTrackNumber?trackingNumber=", },
    { TrackStart: "587", URL: "https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=", },
    { TrackStart: "1Z", URL: "https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=", },
    { TrackStart: "172", URL: "https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=", },
    { TrackStart: "102", URL: "http://www.canadapost.ca/cpotools/apps/track/personal/findByTrackNumber?trackingNumber=", },];

export function copyToClipboard(data, success, ...stillSuccess) {
    if (!data) return;

    console.log(`copyToClipboard: '${data}'`);
    if (window && window.clipboardData) {
        window.clipboardData.setData("Text", data);
        console.log(`'${data}' Copied to window.clipboardData`);
        success(true);
        if (stillSuccess) {
            if (stillSuccess.length > 0) {
                for (var suc of stillSuccess) {
                    suc(false);
                }
            }
        }
        return;
    }
    if (navigator && navigator.clipboard) {
        navigator.clipboard.writeText(data);
        console.log(`'${data}' Copied to navigator.clipboard`);
        success(true);
        if (stillSuccess) {
            if (stillSuccess.length > 0) {
                for (var suc of stillSuccess) {
                    suc(false);
                }
            }
        }
        return;
    }

    success(false);    
}

export function getTrackingURL(trackingNumber) {
    if (!trackingNumber) return;
    for (var map of trackingMap) {
        if (trackingNumber.toUpperCase().startsWith(map.TrackStart)) {
            return `${map.URL}${trackingNumber}`;
        }
    }
    return `https://www.google.com/search?q=${trackingNumber}`
}

export function camelize(str) {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toUpperCase() : word.toLowerCase();
        })
        .replace(/\s+/g, "");
}

export function getIcon(prodType) {
    switch (prodType) {
        case 'Apple phone':
        case 'phone': return faPhone;
        case 'LED':
        case 'LCD':
        case 'Screen':
        case 'Monitor': return faDesktop;
        case 'NbkParts':
        case 'NBK':
        case 'Laptop': return faLaptop;
        case 'SysParts':
        case 'SYS':
        case 'DESKTPOP':
        case 'Dekstop':
        case 'Desktop': return faServer;
        default: return faServer;
    }
}

export function getProdNameForRMA(prodType) {
    switch (prodType) {
        case 'TAB':
        case 'LAP':
        case 'NBKLCD':
        case 'NBK':
        case 'CHR':
            return 'Laptop';

        case 'AIO':
            return 'All-in-One';

        case 'LCD':
            return 'Monitor';

        case 'DMC':
        case 'SER':
        case 'SYS':
        case 'SMC': return 'Desktop';
        case 'ACC':
        case 'ADN':
        case 'BAT':
        case 'DVD':
        case 'ECG':
        case 'HDD':
        case 'KBD':
        case 'MEM':
        case 'MOU':
        case 'PHO':
        case 'PRN':
        case 'PRO':
        case 'PWS':
        case 'STW':
        case 'WATCH':
        default: return 'other';
    }
}

export function getProdName(prodType) {
    switch (prodType) {
        case 'TAB':
            return 'Tablet';
        case 'LAP':
        case 'NBKLCD':
        case 'NBK':
            return 'Laptop';
        case 'CHR':
            return 'Chromebook';
        case 'AIO':
            return 'All-in-One';
        case 'LCD':
            return 'Monitor';
        case 'DMC':
            return 'Dual monitor combination';
        case 'SER':
            return 'Server';
        case 'SYS':
            return 'Desktop';
        case 'SMC':
            return 'Single monitor combination';
        case 'ACC':
            return 'Accessory';
        case 'ADN':
            return 'Add On';
        case 'BAT':
            return 'Battery';
        case 'DVD':
            return 'DVD';
        case 'ECG':
            return 'Electronics';
        case 'HDD':
            return 'Hard Drive';
        case 'KBD':
            return 'Keyboard';
        case 'MEM':
            return 'Memory';
        case 'MOU':
            return 'Mouse';
        case 'PHO':
            return 'Phone';
        case 'PRN':
            return 'Printer';
        case 'PRO':
            return 'Projector';
        case 'STW':
            return 'Software';
        case 'WATCH':
            return 'Watch';
        case 'PWS':
        default: return 'other';
    }
}

export function getProdNameMF(description) {
    if (!description) {
        return '';
    }
    else if (description.toUpperCase().includes('PHONE')) {
        return 'Phone';
    }
    else if (description.toUpperCase().includes('CHROMEBOOK')) {
        return 'Chromebook';
    }
    else if (description.toUpperCase().includes('LAPTOP') || description.toUpperCase().includes('NOTEBOOK')) {
        return 'Laptop';
    }
    else if (description.toUpperCase().includes('DESKTOP') || description.toUpperCase().includes('SERVER') || description.toUpperCase().includes('COMPUTER')) {
        return 'Computer';
    }
    else if (description.toUpperCase().includes('MONITOR') || description.toUpperCase().includes('SCREEN') || description.toUpperCase().includes('LCD ') || description.toUpperCase().includes('LED ')) {
        return 'Monitor';
    } else {
        return description;
    }
    
}

/* Lenovo Product Response
  [
{
    "Id": "CHROMEBOOK-LAPTOPS/LENOVO-CHROMEBOOKS-SERIES/14E-CHROMEBOOK-GEN3/82W7/82W7S07P00/PF4FK45L",
    "Guid": "",
    "Brand": "IPG",
    "Name": "Lenovo 14e Chromebook Gen3  - Type 82W7",
    "Serial": "PF4FK45L",
    "Type": "Product.Serial",
    "ParentID": [
        "782DB5BB-F626-3C14-B4A3-3C18EFAC0674",
        "6D8C8BE3-AAAE-4EC5-8C14-C425CF6A167D",
        "9E489C3B-48C2-4927-96E5-E67742F91277",
        "261513D2-E59D-4A43-A81D-5BF6733A7A98",
        "B7C4D795-A366-4BCD-99AB-E6494CF5C71A"
    ],
    "Image": "https://download.lenovo.com/images/ProdImageLaptops/14e_chrome_gen3.jpg",
    "Popularity": "",
    "IsChina": 0,
    "IsSupported": true,
    "IsSolutionParent": false,
    "ProductBrand": "",
    "Manufacturer": "",
    "MvsInfo": null
}
]
 */

export function getIconMF(description) {
    if (!description) {
        return faTimesCircle;
    }
    else if (description.toUpperCase().includes('PHONE')) {
        return faPhone;
    }
    else if (description.toUpperCase().includes('CHROMEBOOK') || description.toUpperCase().includes('LAPTOP') || description.toUpperCase().includes('NOTEBOOK') ) {
        return faLaptop;
    }
    else if (description.toUpperCase().includes('DESKTOP') || description.toUpperCase().includes('SERVER') || description.toUpperCase().includes('COMPUTER')) {
        return faServer;
    }
    else if (description.toUpperCase().includes('MONITOR') || description.toUpperCase().includes('SCREEN') || description.toUpperCase().includes('LCD ') || description.toUpperCase().includes('LED ')) {
        return faDesktop;
    } else {
        return faServer;
    }    
}
export function getWarrantyDetails(product) {
    console.log(`Checking Equal: '${NOT_ADP}' === '${product.WarrantyLength}' :product.WarrantyLength`);
    if (product.WarrantyLength.toString().includes(NOT_ADP) || !product.IsADP) {
        console.log(`Not an ADP Warranty: ${product.WarrantyLength}`);
        /* Check description for warranty length */
        let warrantyLength = getWarrantyLengthFromDescription(product.Description);
        product.IsCovered = (warrantyLength - product.WarrantyDays) > 0;
        product.RemainingCoverage = (warrantyLength - product.WarrantyDays);
    } else {
        console.log(`We have an ADP Warranty: ${product.WarrantyLength}`);
        product.IsCovered = ((product.WarrantyLength*365) - product.WarrantyDays) > 0;
        product.RemainingCoverage = ((product.WarrantyLength * 365) - product.WarrantyDays);        
    }
}

function getWarrantyLengthFromDescription(description) {
    if (!description || description.includes('/NWR')) {
        return 0;
    }
    else if (description.includes('/1Y')) {
        return 365;
    }
    else if (description.includes('/30D')) {
        return 30;
    }
    else if (description.includes('/90D')) {
        return 90;
    }
    else if (description.includes('/6M')) {
        return 180;
    }
    else if (description.includes('/18M')) {
        return 545;
    }
    else if (description.includes('/3Y')) {
        return 1095;
    }
    else if (description.includes('/2Y')) {
        return 730;
    }
    else if (description.includes('/5Y')) {
        return 1825;
    } else {
        return 365;
    }
}

export function replaceAll(str, find, replace) {
    return str ? str.replace(new RegExp(find, 'g'), replace) : '';
}


export function getPhoneHref(phone) {
    if (!phone.startsWith('+1')) {
        if (!phone.startsWith('1')) {
            phone = `+1${phone}`;
        } else {
            phone = `+${phone}`;
        }
    }
    return replaceAll(replaceAll(replaceAll(phone, 'ext', 'p'),'[.,:-]', ''), ' ','');
}