import React, { useState, useEffect } from "react";
import { getPhoneHref, replaceAll } from "../../service";

import {
    MDBBtn,
    MDBTextArea,
    MDBInput
} from "mdb-react-ui-kit"

import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PHONE_LENGTH_ERROR = 'Phone Number should be 10 digits in length (XXX)XXX-XXXX';
const PHONE_CHAR_ERROR = 'Phone Number should not contain letters or special characters';
const regexPhone = /^[0-9]{1,10}$/;

const ERROR_DETAILED_DESCRIPTION_TOO_SHORT = 'Message seems too short';
const ERROR_DETAILED_DESCRIPTION_NONE = 'Please enter a message';
const ERROR_DETAILED_DESCRIPTION_TOO_LONG = 'Message should be a maximum of 500 characters';

const ERROR_SUBJECT_TOO_SHORT = 'Subject seems too short';
const ERROR_SUBJECT_NONE = 'Please enter a Subject';
const ERROR_SUBJECT_TOO_LONG = 'Subject should be a maximum of 100 characters';

const ContactNow = ({ message, title, email, props, user }) => {
    const [phone, setPhone] = useState(null);
    const [phoneError, setPhoneError] = useState(null);
    const [extension, setExtension] = useState(null);
    const [subject, setSubject] = useState(null);
    const [subjectError, setSubjectError] = useState(null);
    const [detailedDescription, setDetailedDescription] = useState(null);
    const [detailedDescriptionError, setDetailedDescriptionError] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => updateMessage(), [phone, phoneError, extension, subject, subjectError, detailedDescription, detailedDescriptionError]);

    const updatePhone = (e) => {
        console.log(`updatePhone: ${e.target.value}`);
        setPhone(e.target.value);
        if (!e.target.value || e.target.value.length < 1) {
            setPhoneError(null);
        }
        else if (!regexPhone.test(e.target.value)) {
            setPhoneError(PHONE_CHAR_ERROR);
            return;
        } else if (e.target.value.length !== 10) {
            setPhoneError(PHONE_LENGTH_ERROR);
            return;
        } else {
            setPhoneError(null);
        }
        updateMessage();
    };

    const updateExtension = (e) => {
        console.log(`updateExtension: ${e.target.value}`);
        setExtension(e.target.value);
        updateMessage();
    };

    const updateMessage = () => {
        if (!phoneError && detailedDescription && !detailedDescriptionError) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }

    const addSubject = (e) => {
        setSubject(e.target.value);
        checkSubject(e.target.value);
    }

    const sendMessage = (event) => {
        if (isFormValid) {
            if (event) {
                event.preventDefault();
            }
            let body = {
                User: user,
                UserName: user.UserName,
                Token: user.Token,
                Page: 'contactNow',
                UserAccess: user.UserAccess,
                Site: props.brand,                
                Enquiry: {
                    ContactPhone: `${phone}${(extension ? `ext: ${extension}`: '')}`,
                    ContactPhoneHref: getPhoneHref(`${phone}${(extension ? `ext: ${extension}` : '')}`),
                    ContactName: user.FullName,
                    ContactEmail: user.UserName,
                    Title: title,
                    Email: email,
                    Subject: `${title}(${user.FullName.trim()}) ${subject ? subject : ''}`,
                    Message: detailedDescription,
                }
            }   

            const requestOptions = {
                method: "post",
                headers: props.header,
                redirect: "follow",
                changeOrigin: true,
                body: JSON.stringify(body),
            };

            fetch(`${props.baseUrl}ContactNow`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    console.log(`[ContactNow][sendMessage][${title}]> Result: ${result}`);

                    result = JSON.parse(result);
                    if (result && result.status && result.status === 200) {
                        console.log(`[ContactNow][sendMessage][${title}][SUCCESS]> ${result}`);
                        alert(result.message);
                        document.getElementById(`${replaceAll(title, ' ', '')}Form`).reset();                        
                        setIsFormValid(false);
                    } else {
                        console.log(`[ContactNow][sendMessage][${title}][FAILED]> ${result}`);
                        if (result && result.message) {
                            alert(result.message);
                        } else {
                            alert('There appears to have been an error sending the message..');
                        }
                    }
                })
                .catch((error) => console.error('[ContactNow][sendMessage][${title}][ERROR]> There was error sending the message: ' + error));
        }
    }

    const checkDetails = (details) => {
        if (!details) {
            setDetailedDescriptionError(ERROR_DETAILED_DESCRIPTION_NONE);
        } else if (details.length < 5) {
            setDetailedDescriptionError(ERROR_DETAILED_DESCRIPTION_TOO_SHORT);
        } else if (details.length > 500) {
            setDetailedDescriptionError(ERROR_DETAILED_DESCRIPTION_TOO_LONG);
        } else {
            setDetailedDescriptionError(null);
        }
        updateMessage();
    }
    const checkSubject = (details) => {
        if (!details) {
            setSubjectError(ERROR_SUBJECT_NONE);
        } else if (details.length < 5) {
            setSubjectError(ERROR_SUBJECT_TOO_SHORT);
        } else if (details.length > 100) {
            setSubjectError(ERROR_SUBJECT_TOO_LONG);
        } else {
            setSubjectError(null);
        }
        updateMessage();
    }


    const updateDetailedDescription = (e) => {
        console.log(`updateDetailedDescription: ${e.target.value}`);
        setDetailedDescription(e.target.value);
        checkDetails(e.target.value);
    }

    return (
        <React.Fragment>
            <h2 class="my-3">{ title}</h2>
            <p>{ message }</p>
            <p><b>{"Reply to Name: "}</b>{user.FullName}</p>
            <p><b>{"Reply to Email: "}</b>{user.UserName}</p>

            <form id={`${replaceAll(title, ' ', '')}Form`}>
            <MDBInput wrapperClass='mb-4' label='Add a Reply to Phone Number' id={`${replaceAll(title, ' ', '')}EnquireNowPhone`}
                onChange={updatePhone} type='text' size="lg"
                style={phoneError ? props.errorStyle : null}
            >
                <div id='formPhoneErrorLabel' className='form-helper'>
                    {phoneError}
                </div>
            </MDBInput>

            <MDBInput wrapperClass='mb-4' maxLength={4} label='Extension'
                onChange={updateExtension} id='formExtension'
                type='tel' size="lg" />

            <MDBInput wrapperClass='mb-4' label='Subject *' id={`${replaceAll(title, ' ', '')}EnquireNowSubject`}
                onChange={addSubject} type='text' size="lg" style={subjectError ? props.errorStyle : null}
            >
                <div id='subjectErrorLabel' className='form-helper'>
                    {subjectError}
                </div>
            </MDBInput>


            <MDBTextArea wrapperClass='mb-4' id='formDetailedDescription' maxLength={500} onChange={(e)=>updateDetailedDescription(e)}
                rows={4} label='Message *' style={detailedDescriptionError ? props.errorStyle : null}
            >
                <div id='detailedDescriptionErrorLabel' className='form-helper'>
                    {detailedDescriptionError}
                </div>
            </MDBTextArea>
            <br />
                <MDBBtn onClick={sendMessage} disabled={!isFormValid} >Send Now</MDBBtn>
            </form>
        </React.Fragment>
            );
}

export default ContactNow;
